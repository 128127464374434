<template>
  <div class="app-container">
    <el-form :model="searchForm" inline size="small">
      <el-form-item label="操作人">
        <el-input v-model="searchForm.userName" clearable placeholder="操作人"></el-input>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="searchForm.time"
          type="daterange"
          align="left"
          clearable
          unlink-panels
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
    </el-form>
    <el-table :data="dataList" border size="small"
      :header-cell-style="{background: '#41A1FF',color: '#ffffff'}"
      height="calc(100vh - 220px)">
      <el-table-column label="操作人" prop="userName" width="150" align="center"></el-table-column>
      <el-table-column label="创建时间" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | dateFormate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="日志信息" prop="describe"></el-table-column>
    </el-table>
    <div style="margin-top:1em; text-align:center">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page="pageNum"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getUserActionLog } from '@/api/account'
import * as options from '@/utils/index'
export default {
  // 过滤器
  filters: {
    dateFormate(val) {
      if (!val) {
        return ''
      } else {
        const time = new Date(val)
        const Y = time.getFullYear()
        const M = (time.getMonth() + 1).toString().padStart(2, '0')
        const D = time.getDate().toString().padStart(2, '0')
        const h = time.getHours().toString().padStart(2, '0')
        const m = time.getMinutes().toString().padStart(2, '0')
        const s = time.getSeconds().toString().padStart(2, '0')
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      }
    }
  },
  data() {
    return {
      searchForm: {
        userName: '',
        time: null
      },
      dataList: [],
      loading: false,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pickerOptions: {
        shortcuts: options.pickerOptions
      }
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    getData() {
      const params = {
        userName: this.searchForm.userName,
        startTime: this.searchForm.time ? options.parseTime(this.searchForm.time[0]) : null,
        endTime: this.searchForm.time ? options.parseTime(this.searchForm.time[1]) : null,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      this.loading = true
      getUserActionLog(params).then(res => {
        if (res.code === '000000') {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.dataList = []
          this.total = 0
        }
        this.loading = false
      }).catch(() => {
        this.dataList = []
        this.total = 0
        this.loading = false
      })
    },
    search() {
      if (this.pageNum === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.pageNum = page
      this.getData()
    }
  }
}
</script>
